import React, { useRef } from "react";
import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import CheckAuth from "./Components/CheckAuth/CheckAuth";


function App() {

  return (
    <div className="screen-body">
      <NavBar />
      <CheckAuth />
    </div>
  );
}

export default App;

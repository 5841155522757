import React from 'react'
import './NavBar.css'
import LogoutButton from '../Logout/Logout'
import appLogo from './appLogo.svg'

function NavBar() {

  
  return (
    <div className="Rectangle-21">
      <img src={appLogo} alt="appLogo" className="appLogo" />
      <div className='signUp'>
        <LogoutButton />
      </div>
    </div>
  )
}

export default NavBar
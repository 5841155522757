import React from 'react'
import { useState, useRef } from 'react'
import './UploadBox.css'
import uploadLogo from './uploadLogo.svg'
import Uploading from '../Uploading/Uploading'
import DownloadJson from '../DownloadJson/DownloadJson'
import download from 'downloadjs';


function UploadBox({selectedOption, isSignature, setFormState, formState, confidence}) {

  const [file, setFile] = useState(null) 


  const fileInput = useRef(null);


  // Function to download file, creates a blob and triggers download
  const downloadFile = (data, filename,type = 'application/json') => {
    const url = window.URL.createObjectURL(new Blob([data], {type: type}));
    const link = document.createElement('a');
    link.href = url;
    const now = new Date();
    const file_name = file.name.slice(0, -4)+now.toISOString()+filename;
    link.setAttribute('download', `${file_name}.json`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  // Function to handle click event, triggers file input click
  const handleClick = () => {
    if (formState >= 4) setFormState(2);
    fileInput.current.click();
  };

  // Function to handle spinner
  const handleSpinner = () => {
    setFormState(1);
    setTimeout(() =>  setFormState(2), 2000);
  }

  // Function to handle drop event, prevents default and sets the file to state
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    if (files.length > 0) {
      handleSpinner();
      setFile(...files);
      console.log(files);
    }
  }

  // Function to handle drag over event, prevents default
  const handleDragOver = (event) => {
    event.preventDefault()
  }

  // Callback function to handle result, calls handleFileUpload with given format
  const getResultCallback = async (d_fomat) =>{
    handleFileUpload(d_fomat);
  }

  // Function to handle file upload, sends a POST request to the server with the file and other form data
  const handleFileUpload = async (fileType) => {
    try {
      setFormState(3);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("form_number", selectedOption);
      formData.append("confidence", confidence/100);
      const response = await fetch("https://projecthealth.tech:8000/upload/", {
        method: "POST",
        body: formData,
      });
      
      if (response.ok){
        const js_data = await response.text();
        const data = JSON.parse(js_data);
        console.log(data.response1, data.response2);
        const data1 = data.response1;
        const data2 = data.response2;
        if (confidence==100){
          downloadFile(data.response, '');
        }
        else{
          downloadFile(data1, '_response1');
          downloadFile(data2, '_response2');
        }
      } 
      else {
        setFormState(5);
        throw new Error(`Upload request failed: ${response.status}`);
      }
if (isSignature) {
  try {
    const signature = await fetch("https://projecthealth.tech:8000/get_signature/", {
      method: "POST",
      body: formData,
    });
    if (signature.ok) {
      const data = await signature.blob();
      console.log(data);
      setFormState(4);
      const content = signature.headers.get('content-type');
      const now = new Date();
      const file_name = file.name.slice(0, -4)+now.toISOString();
      if (selectedOption!=4)
        download(data, `${file_name}.pdf`, content);
      else{
        download(data, `${file_name}.zip`, content, 'application/zip');
      }
        
    } else {
      setFormState(5);
      throw new Error(`Signature request failed: ${signature.status}`);
    }
  } catch (error) {
    setFormState(5);
    console.log(error);
    alert("Error while downloading Signature", error.message);
  }
}
else{
  setFormState(4)
} 
    } catch (error) {
      setFormState(5);
      alert("Error whlie fetching Data",error.message);
    }
  }

  // Function to handle file change event, sets the selected file to state and triggers spinner
  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setFile(() => event.target.files[0]);
    handleSpinner();
  };

  // Function to handle drag start event, sets the event data to the target id
  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id)
  }
  return (
    <>
    <div className="Rectangle-1" onClick={handleClick} onDrop={handleDrop} onDragOver={handleDragOver} onDragStart={handleDragStart} disabled={selectedOption===''}>
    <input type="file" ref={fileInput} hidden onChange={handleFileChange} />
      <img src={uploadLogo} alt="uploadLogo" className="uploadLogo" />
      <span className="Upload-File">
        Upload File
      </span>
      <span className="Drop-or-select-files-from-device">
        Drop or select files from device
      </span>
      <span className="max-50MB-pdf-file-only">
        max. 50MB, .pdf file only
      </span>
    </div>
    { formState>0 && <Uploading fileName={file?.name} formState={formState} />}
    <DownloadJson getResultCallback={getResultCallback} selectedOption={selectedOption}/>
    </>
  )
}

export default UploadBox
import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import FormDetails from "../FormDetails/FormDetails";
import './CheckAuth.css'




function CheckAuth() {

    // Function to handle login
    const login = () => {
        try{
            loginWithRedirect();
        }
        catch(error){
            console.log(error);
        }
    }

    const { isLoading } = useAuth0();
    const { isAuthenticated } = useAuth0();
    const { loginWithRedirect } = useAuth0();
    return (
        <>
          {isLoading ? <div>Checking for the Authentication </div> :
             isAuthenticated ? <FormDetails /> :
             login() }
        </>
      );
}

export default CheckAuth